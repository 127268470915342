import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/styles/border.css'
import '@/assets/styles/reset.css'
import '@/assets/styles/scrollbar.css'; // 根据你的文件路径调整
import '@/router/permission.js'
import zhCn from 'element-plus/es/locale/lang/zh-cn'// 国际化中文
import {buttonAuthority} from "@/sys/mixin";
import {QuillEditor,Quill} from '@vueup/vue-quill'// 富文本编辑器
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {container,QuillWatch,ImageExtend} from "quill-image-extend-module";
import VueCropper from 'vue-cropper';// 裁剪组件
import 'vue-cropper/dist/index.css'
import './assets/styles/color.scss'; // 路径应与您的文件结构相匹配
import VueLazyload from 'vue-lazyload'
import SearchBar from '/src/components/searchBar'
import TableAEDialog from '/src/components/tableAEDialog'
import TableMenu from '/src/components/tableMenu'
import TableAction from '/src/components/tableAction'
import TableColumn from '/src/components/tableColumn'
import CropperDialog from '/src/components/cropperDialog'
import CropperEditorDialog from '/src/components/cropperEditorDialog'
import ImageView from '/src/components/imageView'
import Snow from '/src/components/snow'
import SvgIcon from '@/components/svgIcon';

const app = createApp(App)
app.mixin(buttonAuthority)
app.use(ElementPlus, {locale: zhCn,})
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(VueCropper)
app.use(VueLazyload, {})
app.component('SvgIcon', SvgIcon);// SVG组件
app.component('QuillEditor', QuillEditor)// 富文本编辑器
app.component('SearchBar', SearchBar)
app.component('TableMenu', TableMenu)
app.component('TableAEDialog', TableAEDialog)
app.component('TableAction', TableAction)
app.component('TableColumn', TableColumn)
app.component('CropperDialog', CropperDialog)
app.component('CropperEditorDialog', CropperEditorDialog)
app.component('ImageView', ImageView)
app.component('Snow', Snow)
app.mount('#app')

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 首页 W
import indexImageWW from './assets/img/wallpaper/indexImageWW.jpg';
global.indexImageWW = indexImageWW;
import indexImageWB from './assets/img/wallpaper/indexImageWB.jpg';
global.indexImageWB = indexImageWB;
// 首页 H
import indexImageHW from './assets/img/wallpaper/indexImageHW.jpg';
global.indexImageHW = indexImageHW;
import indexImageHB from './assets/img/wallpaper/indexImageHB.jpg';
global.indexImageHB = indexImageHB;

// 登陆 W/H
import LoginImageW from './assets/img/wallpaper/LoginImageW.jpg';
global.LoginImageW = LoginImageW;
import LoginImageH from './assets/img/wallpaper/LoginImageH.jpg';
global.LoginImageH = LoginImageH;

// 注册 W/H
import registerImageW from './assets/img/wallpaper/registerImageW.jpg';
global.registerImageW = registerImageW;
import registerImageH from './assets/img/wallpaper/registerImageH.jpg';
global.registerImageH = registerImageH;

// 忘记密码 W/H
import resetPasswordImageW from './assets/img/wallpaper/resetPasswordW.jpg';
global.resetPasswordImageW = resetPasswordImageW;
import resetPasswordImageH from '../src/assets/img/wallpaper/resetPasswordH.jpg';
global.resetPasswordImageH = resetPasswordImageH;

// 锁屏 W/H
import sleepImageW from './assets/img/wallpaper/sleepImageW.jpg';
global.sleepImageW = sleepImageW;
import sleepImageH from '../src/assets/img/wallpaper/sleepImageH.jpg';
global.sleepImageH = sleepImageH;


// 进入vip页面的凭证
global.cvAuthorityValue = false;

// 动态导入./icons/svg目录下所有.svg 文件
const svgRequired = require.context('./icons/svg', false, /\.svg$/);
svgRequired.keys().forEach(item => {
    svgRequired(item); //显示
});

// 页面方法缩小报错 ResizeObserver loop limit exceeded
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

// 富文本
Quill.register('modules/ImageExtend', ImageExtend)
let BlockEmbed = Quill.imports['blots/embed'];
// 自定义img标签
class ImageBlot extends BlockEmbed {
    static create (value) {
        let node = super.create();
        node.setAttribute('src', value.src);
        node.setAttribute('width', value.width);
        return node;
    }
    static value (node) {
        return {
            src: node.getAttribute('src'),
            width: node.getAttribute('width')
        }
    }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
Quill.register(ImageBlot)
