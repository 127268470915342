<template>
  <svg class="SvgIcon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script setup>
import {defineProps, computed, onMounted} from 'vue'

const props = defineProps({
  icon: {
    type: String,
    required: true
  }
})

const iconName = computed(() => {
  return `#icon-${props.icon}`
})

</script>

<style lang="scss" scoped>

.custom-path {
  fill: none;
  stroke: red;
  stroke-width: 2;
  stroke-dasharray: var(--l);
  stroke-dashoffset: var(--l);
  stroke-linecap: round;
  animation: stroke 4s forwards;
}
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

.SvgIcon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
