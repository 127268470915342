<template>
  <!-- 雪花元素 -->
  <div v-for="n in numberOfSnowflakes" :key="n" class="snowflake-container">
    <div class="snowflake">
      <img src="@/assets/img/component/snow.png" alt="snowflake"/>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, nextTick } from 'vue';

const numberOfSnowflakes = ref(60); // 可以将雪花数量设置为可配置项

let throttleTimer = null;
let startMoveTimer = null;
let startClientX = 0;
let targetOffset = 0; // 目标偏移量，用于缓动

// 线性插值函数，用于逐步靠近目标值
const lerp = (start, end, alpha) => {
  return start + (end - start) * alpha;
};

// 鼠标移动事件处理函数
const handleMouseMove = (event) => {
  if (throttleTimer) {
    return; // 如果在节流时间内，直接返回，不做任何处理
  }
  // 清除之前的定时器，重新开始计时
  if (startMoveTimer !== null) {
    clearTimeout(startMoveTimer);
  }
  // 记录初始移动点位置
  if (startClientX === 0) {
    startClientX = event.clientX;
  }
  // 设置一个延时判断鼠标是否停止
  startMoveTimer = setTimeout(() => {
    // 0.1秒后判断鼠标是否停止，如果没有新的移动事件，则认为鼠标已停止
    const endClientX = event.clientX; // 记录停止后的鼠标位置
    if (Math.abs(endClientX - startClientX) > 100) {
      // 如果移动距离大于100，计算目标偏移量
      const move = (endClientX - startClientX) * 0.15; // 移动距离的15%
      targetOffset += move; // 更新目标偏移量
    }
    // 启动缓动效果，每隔20毫秒逐步逼近目标位置
    const smoothMove = setInterval(() => {
      document.querySelectorAll('.snowflake').forEach((el) => {
        // 获取当前偏移量
        const currentTransform = el.style.transform || "translateX(0px)";
        const currentOffset = parseFloat(currentTransform.replace(/[^\d\-\.]/g, '')) || 0;
        // 使用线性插值函数计算新的偏移量，使当前位置逐步逼近目标位置
        const newOffset = lerp(currentOffset, targetOffset, 0.7); // alpha 值越小，越慢逼近

        // 更新雪花的 transform 样式
        el.style.transform = `translateX(${newOffset}px)`;

        // 如果已经接近目标位置，则清除定时器
        if (Math.abs(newOffset - targetOffset) < 0.5) {
          clearInterval(smoothMove);
        }
      });
    }, 20); // 每20毫秒更新一次位置

    // 无论鼠标是否停止或移动距离如何，都进行700毫秒的节流控制
    throttleTimer = setTimeout(() => {
      throttleTimer = null; // 700毫秒后解除节流限制
    }, 700);

    // 重置初始位置和定时器
    startClientX = 0;
    startMoveTimer = null;
  }, 100); // 0.1秒检测时间段
};

// 挂载和卸载生命周期钩子
onMounted(() => {
  window.addEventListener('mousemove', handleMouseMove);
  // 雪花逻辑
  nextTick(() => {
    document.querySelectorAll('.snowflake-container').forEach((el) => {
      el.style.setProperty('--size', Math.random());
      el.style.setProperty('--duration', Math.random());
      el.style.setProperty('--delay', Math.random());
      el.style.setProperty('--left', Math.random());
    });
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('mousemove', handleMouseMove);
});
</script>

<style scoped lang="scss">
/* 外层容器：负责垂直方向的下落 */
.snowflake-container {
  position: absolute;
  top: -10vh; /* 初始位置在视图顶部 */
  left: calc(var(--left) * 100vw); /* 完全随机的水平位置 */
  animation: snowfall linear infinite;
  animation-duration: calc(5s + var(--duration) * 15s); /* 随机持续时间，基础是 5s 加上最多 15s 的随机 */
  animation-delay: calc(var(--delay) * 5s); /* 随机延迟，最多提前开始 5s */
}

/* 内层雪花元素：负责水平移动，由 JavaScript 动态控制 */
.snowflake {
  z-index: 1000;
  position: absolute; /* 保留原有 position 属性 */
  top: 0; /* 设置为相对容器的初始位置 */
  color: white !important;
  user-select: none;
  font-size: calc(0.75em + var(--size) * 1em); /* 随机大小 */
  transition: transform 0.8s ease-in-out; /* 控制水平移动的过渡效果 */
  img {
    width: calc(0.5em + var(--size) * 0.5em); /* 减小基础大小和随机大小因子 */
    height: auto; /* 保持图片的宽高比 */
  }
}

@keyframes snowfall {
  0% {
    transform: translateY(-10vh) translateX(var(--offset, 0px));
  }
  100% {
    transform: translateY(100vh) translateX(var(--offset, 0px));
  }
}
</style>
